
import {
    Button,
    Image,
    Table,
    Tag,
} from "antd";
import dateformat from 'dateformat'
import { useContext, useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import Mission from "../../models/Mission";
import Order from "../../models/Order";


const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'name',
        key: 'name',
        render: (name) => utils.cutLongString(name),
    },
    {
        title: VN.FULLNAME,
        dataIndex: 'info_user',
        key: 'info_user',
        render: (info_user) => `${info_user.first_name} ${info_user.last_name}`,
    },
    {
        title: VN.PHONE,
        dataIndex: 'info_user',
        key: 'info_user',
        render: (info_user) => info_user.phone,
    },
    {
        title: VN.ADDRESS,
        dataIndex: 'info_user',
        key: 'info_user',
        render: (info_user) => utils.cutLongString(info_user.address),
    },
    {
        title: VN.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status) => utils.mapOrderStatus(status)
    },
    {
        title: VN.TIME,
        dataIndex: 'time',
        key: 'time',
        render: (time) => dateformat(time, constants.DATE_FORMAT)
    },
    {
        title: VN.UPDATED_AT,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) => dateformat(updatedAt, constants.DATE_FORMAT)
    },
    {
        title: VN.CREATED_AT,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => dateformat(createdAt, constants.DATE_FORMAT)
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions',
    },
];

export default function Page() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const cancelHandler = async function (id) {
        try {
            const response = await Request.xhr(
                `${constants.API_DOMAIN}/order/cancel`,
                false,
                { orderId: id },
                "POST"

            )
            if (response.status === 200) {
                await fetchData()
            }

        } catch (error) {
            console.log(error)
        }
    }
    const checkHandler = async function (id) {
        try {
            const response = await Request.xhr(
                `${constants.API_DOMAIN}/order/check`,
                false,
                { orderId: id },
                "POST"

            )
            if (response.status === 200) {
                await fetchData()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/order`)
            let data = utils.mapDataModel(response.data, Order)
            for (let item of data) {
                const data = { ...item }
                item.actions = <ActionButtons
                    editText={VN.CONFIRM}
                    deleteText={VN.CANCEL}
                    onEdit={() => checkHandler(item.id)}
                    onDelete={() => cancelHandler(item.id)}
                />
            }
            setData(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            {/* <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('edit')}
            >{VN.CREATE}</Button> */}
            <Table
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

