/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import React, { useEffect } from "react";
import BoxGiftPage from "./pages/boxgift/boxgift";
import BoxGiftEdit from "./pages/boxgift/boxgift.edit";

import GiftPage from "./pages/gift/gift";
import GiftEdit from "./pages/gift/gift.edit";

import Card from "./pages/card/card";
import Voucher from "./pages/voucher/voucher";
import Shop from "./pages/shop/shop";
import ShopEdit from "./pages/shop/shop.edit";

import Pot from "./pages/pot/pot";
import PotEdit from "./pages/pot/pot.edit";


import Consumable from "./pages/consumable/consumable";
import ConsumableEdit from "./pages/consumable/consumable.edit";

import EffectCard from "./pages/effectCard/effectCard";
import EffectCardEdit from "./pages/effectCard/effectCard.edit";

import Mission from "./pages/mission/mission";
import MissionEdit from "./pages/mission/mission.edit";

import Order from "./pages/order/order";

import Question from "./pages/question/question";

import Config from "./pages/config/config";

import User from "./pages/user/user";
import UserEdit from "./pages/user/user.edit";

function App() {
  const token = window.localStorage.getItem('token')

  const initData = async function () {
    if (!token) return
    try {

    } catch (error) {
      console.log("INIT DATA IN APP.JS ERROR", error)
    }
  }

  useEffect(() => {
    initData()
  }, [])

  if (!token) {
    return (
      <Routes>
        <Route
          path="*"
          element={<SignIn />}
        />
      </Routes>
    )
  }
  return (
    <Routes>
      {/* <Route path="/sign-up" exact element={<SignUp />} /> */}
      <Route path="/sign-in" exact element={<SignIn />} />
      <Route exact path="/dashboard" element={<Home />} />
      <Route path="/" element={<Main />}>

        <Route exact path="boxgift" element={<BoxGiftPage />} />
        <Route exact path="boxgift/edit" element={<BoxGiftEdit />} />
        {/* <Route exact path="boxgift/edit" element={<EditLivestream />} /> */}

        <Route exact path="gift" element={<GiftPage />} />
        <Route exact path="gift/edit" element={<GiftEdit />} />

        <Route exact path="card" element={<Card />} />

        <Route exact path="voucher" element={<Voucher />} />

        <Route exact path="shop" element={<Shop />} />
        <Route exact path="shop/edit" element={<ShopEdit />} />

        <Route exact path="pot" element={<Pot />} />
        <Route exact path="pot/edit" element={<PotEdit />} />

        <Route exact path="consumable" element={<Consumable />} />
        <Route exact path="consumable/edit" element={<ConsumableEdit />} />

        <Route exact path="effectCard" element={<EffectCard />} />
        <Route exact path="effectCard/edit" element={<EffectCardEdit />} />

        <Route exact path="mission" element={<Mission />} />
        <Route exact path="mission/edit" element={<MissionEdit />} />

        <Route exact path="order" element={<Order />} />

        <Route exact path="question" element={<Question />} />

        <Route exact path="config" element={<Config />} />
        <Route exact path="user" element={<User />} />
        <Route exact path="user/edit" element={<UserEdit />} />
      </Route>
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
