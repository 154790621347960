
import _ from 'lodash'
import constants from './constants';
import VN from './vn';

const utils = {
    removeUserSavedInfo() {
        window.localStorage.clear()
        window.location.reload(true)
    },

    mapDataModel(listData = [], model) {
        let results = [];
        if (!_.isEmpty(listData)) {
            results = listData.map(dr => new model(dr))
        }
        return results;
    },
    secondsToHms(seconds = 0) {
        const dateObj = new Date(seconds * 1000);
        const h = dateObj.getUTCHours();
        const m = dateObj.getUTCMinutes();
        const s = dateObj.getSeconds();

        return h.toString().padStart(2, '0') + ':' +
            m.toString().padStart(2, '0') + ':' +
            s.toString().padStart(2, '0');
    },
    objectToFormData(obj = {}) {
        let formData = new FormData();

        for (let key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
    },
    sleep: (ms) => new Promise(resolve => setTimeout(resolve, ms)),

    parseHTML: function (html) {
        if (!html) return ""
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        let text = tempDivElement.innerText || tempDivElement.textContent
        return text
    },

    cutLongString: function (text) {
        if (!text) return text
        if (text.length <= constants.MAXIMUM_LINE_LENGTH) return text
        return text = text.slice(0, constants.MAXIMUM_LINE_LENGTH) + "..."
    },

    mapOrderStatus: function (status) {
        switch (status) {
            case constants.ORDER_STATUS.PENDING:
                return VN.PENDING
            case constants.ORDER_STATUS.CHECKED:
                return VN.CHECKED
            case constants.ORDER_STATUS.CANCELED:
                return VN.CANCELED
            default:
                return null;
        }
    },

    mapCardStatus: function (status) {
        switch (status) {
            case constants.CARD_STATUS.UNUSED:
                return VN.UNUSED
            case constants.CARD_STATUS.USED:
                return VN.USED
            default:
                return null;
        }
    },

    mapNotificationTypeTarget: function (target) {
        switch (target) {
            case constants.NOTIFICATION_TARGET.ALL:
                return VN.ALL
            case constants.NOTIFICATION_TARGET.INDIVIDUAL:
                return VN.INDIVIDUAL
            case constants.NOTIFICATION_TARGET.GROUP:
                return VN.GROUP
            default:
                return VN.ERROR;
        }
    },

    mapLivestreamStatus: function (status) {
        switch (status) {
            case constants.LIVESTREAM_STATUS.COMING:
                return VN.COMING
            case constants.LIVESTREAM_STATUS.SHOWING:
                return VN.SHOWING
            case constants.LIVESTREAM_STATUS.CLOSED:
                return VN.CLOSED
            case constants.LIVESTREAM_STATUS.INVISIBLE:
                return VN.INVISIBLE
            default:
                return VN.ERROR;
        }
    },

    mapGender: function (gender) {
        switch (gender) {
            case 1:
                return VN.MALE
            case 2:
                return VN.FEMALE
            case 0:
                return VN.OTHER
            default:
                return null;
        }
    }
}

export default utils