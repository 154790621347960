import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Gift {
    constructor(data = {}) {
        this.name = data.name || null
        this.count_on_day = data.count_on_day || null
        this.count_on_hours = data.count_on_hours || null
        this.count_used = data.count_used || 0
        this.type = data.type || null
        this.price = data.price || 0
        this.remain = data.remain || 0
        this.number_limit_day = data.number_limit_day || 0
        this.percent_send = data.percent_send || 0
        this.coin_from = data.coin_from || 0
        this.coin_to = data.coin_to || 0

        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.id = data.gift_newId || null
        this.key = this.id
    }
}