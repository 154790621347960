import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

class User {
    constructor(data = {}) {
        this.id = data.user_id || null

        this.first_name = data.first_name || ''
        this.last_name = data.last_name || ''
        this.phone = data.phone || ''
        this.address = data.address || ''
    }
}

export default class Order {
    constructor(data = {}) {
        this.name = data.name || ''
        this.status = data.status || null
        this.time = data.time ? new Date(data.time) : null
        this.info_user = new User({ ...data.info_user })

        this.id = data.orderId || null
        this.createdAt = data.createdAt ? new Date(data.createdAt) : null
        this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null
        this.key = this.id
    }
}