import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Question {
    constructor(data = {}) {
        this.question = data.question || ''
        this.mark = data.mark || 0
        this.imglink = data.imglink || null
        this.correct = data.correct || ''
        this.category = data.category || ''
        this.answer = data.answer || []
        this.timeQuestStart = data.timeQuestStart || ''
        this.timeQuestEnd = data.timeQuestEnd || ''

        this.id = data.index || null
        this.createdAt = data.createdAt ? new Date(data.createdAt) : null
        this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null
        this.key = this.id
    }
}