
import {
    Button,
    Image,
    Table,
    Tag,
    Modal,
    Upload,
    message,
} from "antd";
import dateformat from 'dateformat'
import { useContext, useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import Question from "../../models/Question";
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import * as XLSX from "xlsx";

const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'question',
        key: 'question',
        render: (question) => utils.cutLongString(question),
    },
    // {
    //     title: VN.CATEGORY,
    //     dataIndex: 'category',
    //     key: 'category',
    // },
    // {
    //     title: VN.LEVEL,
    //     dataIndex: 'level',
    //     key: 'level',
    // },
    // {
    //     title: VN.POINT,
    //     dataIndex: 'mark',
    //     key: 'mark',
    // },
    // {
    //     title: VN.IMAGE,
    //     dataIndex: 'imglink',
    //     key: 'imglink',
    //     render: (imglink) => <Image src={imglink}
    //         width={constants.IMAGE_DEFAULT_WIDTH}
    //         height={constants.IMAGE_DEFAULT_HEIGHT}
    //     />
    // },
    {
        title: VN.CORRECT,
        dataIndex: 'correct',
        key: 'correct',
    },
    {
        title: VN.UPDATED_AT,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) => dateformat(updatedAt, constants.DATE_FORMAT)
    },
    {
        title: VN.CREATED_AT,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => dateformat(createdAt, constants.DATE_FORMAT)
    },
    // {
    //     title: VN.ACTION,
    //     key: "actions",
    //     dataIndex: 'actions',
    // },
];

// const excelColumns = [
//     {
//         title: VN.CATEGORY,
//         dataIndex: '__EMPTY',
//         key: '__EMPTY',
//     },

// ]

export default function Page() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [excelFile, setExcelFile] = useState([])
    const [excelData, setExcelData] = useState([])
    const [fileLoading, setFileLoading] = useState(false)
    const [excelColumns, setExcelColumn] = useState([])



    // console.log({ excelColumns })
    // const deleteHandler = async function (id) {
    //     try {
    //         // const response = await Request.xhr(
    //         //     `${constants.API_DOMAIN}/live/delete`,
    //         //     false,
    //         //     { liveId: id },
    //         //     "POST"

    //         // )
    //         // if (response.status === 200) {
    //         //     await fetchData()
    //         // }

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const onSubmitData = async function () {
        setFileLoading(true)

        try {
            let dataSend = excelData
            dataSend = dataSend.map((question) => {
                question.answer = question.answer.split("|")
                return question
            })
            await Request.xhr(
                `${constants.API_DOMAIN}/question/uploadExcel`,
                true,
                {
                    data: dataSend
                },
                "POST"
            )
        } catch (error) {
            console.log(error)
        }


        setFileLoading(false)
    }

    const onSubmitExcel = async function (file) {
        setFileLoading(true)

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" })

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
                setFileLoading(false)
            };
        });

        promise.then((data) => {
            console.log("EXCEL", data)
            setExcelColumn(Object.keys(data[0]).map((item, index) => ({
                title: item,
                key: item,
                dataIndex: item,
            })))
            setExcelData(data)
            setFileLoading(false)
        });
    }

    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/question`)
            let data = utils.mapDataModel(response.data, Question)
            for (let item of data) {
                const data = { ...item }
                item.actions = <ActionButtons
                    enableDelete={false}
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                            oldData: { ...data },
                        }
                    })}
                // onDelete={() => deleteHandler(item.id)}
                />
            }
            setData(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
            >{VN.CREATE}</Button>
            <Modal
                width={1200}
                title={VN.UPLOAD_EXCEL}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button
                        disabled={excelFile.length === 0}
                        key="submit" type="secondary"
                        loading={loading} onClick={() => onSubmitExcel(excelFile[0].originFileObj)}>
                        {VN.READ_FILE}
                    </Button>,
                    <Button
                        disabled={excelFile.length === 0}
                        key="submit" type="primary"
                        loading={loading} onClick={() => onSubmitData()}>
                        {VN.CONFIRM}
                    </Button>,
                ]}

            >
                <Upload
                    maxCount={1}
                    multiple={false}
                    fileList={excelFile}
                    onChange={(info) => {
                        const { fileList } = info
                        setExcelFile(fileList)
                    }}
                    beforeUpload={() => false}
                >
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD}</Button>
                </Upload>
                <Table
                    loading={fileLoading}
                    dataSource={excelData}
                    columns={excelColumns}
                    scroll={{ x: constants.TABLE_SCROLL_X, }} />
            </Modal>
            <Table
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

