import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Select,
    Alert,
    DatePicker,
    Row, Image, Col,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import dateFormat from 'dateformat';
import ReactSelect from 'react-select';
import LoadingSpin from '../../components/loadingSpin';
import { MultiSelect } from "react-multi-select-component";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from 'ckeditor5-build-classic-custom';
import Pot from '../../models/Pot';
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function EditPage() {
    const endPoint = `${constants.API_DOMAIN}/pot`

    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { state } = useLocation()
    const { id, isEditing, oldData } = state || { id: null, isEditing: false, oldData: new Pot() }
    const [data, setData] = useState(oldData)

    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                ...formData,
            }

            if (isEditing) {
                reqData.potId = data.id
            }

            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
                // true
            )

            if (resData.status === 200) {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    if (isFetching) {
        return <LoadingSpin />
    }

    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={(value) => submitData(value)}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}

            <Form.Item
                initialValue={data.name}
                label={VN.NAME}
                name="name"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input placeholder={data.name} />
            </Form.Item>

            <Form.Item
                initialValue={data.add_point}
                label={VN.ADD_POINT}
                name="add_point"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input type={"number"} placeholder={data.add_point} />
            </Form.Item>

            <Form.Item
                initialValue={data.add_leaf}
                label={VN.ADD_LEAF}
                name="add_leaf"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input type={"number"} placeholder={data.add_leaf} />
            </Form.Item>

            <Form.Item
                initialValue={data.reduce_shit_cooldown}
                label={VN.REDUCE_SHIT_COOLDOWN}
                name="reduce_shit_cooldown"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input type={"number"} placeholder={data.reduce_shit_cooldown} />
            </Form.Item>

            <Form.Item
                initialValue={data.reduce_water_cooldown}
                label={VN.REDUCE_WATER}
                name="reduce_water_cooldown"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input type={"number"} placeholder={data.reduce_water_cooldown} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
