import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class User {
    constructor(data = {}) {
        this.isBlock = data.isBlock || null
        this.firstname = data.firstname || null
        this.lastname = data.lastname || null
        this.phone = data.phone || null
        this.address = data.address || null
        this.coin = data.coin || null
        this.lixi = data.lixi || null

        this.id = data.id || null
        this.createdAt = data.createdAt ? new Date(data.createdAt) : null
        this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null
        this.key = this.id
    }
}