import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

class GameTemplate {
    constructor(data = {}) {
        this.numberPlay = data.numberPlay || 0
        this.coinJoin = data.coinJoin || 0
        this.listBet = data.listBet || []
        this.time = data.time || 0
        this.botMaxBet = data.botMaxBet || 0
        this.botMaxLose = data.botMaxLose || 0
    }
}

export default class GameConfig {
    constructor(data = {}) {
        this.gameContest = data.gameContest || {}
        this.gameOanTuTi = new GameTemplate({ ...data.gameOanTuTi })
        this.gameOanquan = new GameTemplate({ ...data.gameOanquan })
        this.gameCaro = new GameTemplate({ ...data.gameCaro })
        this.gameKeoco = new GameTemplate({ ...data.gameKeoco })
        this.targetDonateLeaf = Number(data.targetDonateLeaf) || 0
        this.timeSlotAddLixi = data.timeSlotAddLixi || [
            { start: "00:00:00", end: "00:00:00" },
            { start: "00:00:00", end: "00:00:00" },
        ]
        this.levelWater = data.levelWater || []
        this.timeStartLixi = new Date(data.timeStartLixi)
        this.timeFinishLixi = new Date(data.timeFinishLixi)
        this.timeFinishDonate = new Date(data.timeFinishDonate)
        this.coinDaily = data.coinDaily || null
        this.coin_bonus_invite = data.coin_bonus_invite || null
    }
}