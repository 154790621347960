
import {
    Button,
    Image,
    Modal,
    Table,
    Tag,
    Form,
    Radio,
    Upload,
    Input,
    Select,
    Alert,
    DatePicker,
    Row, Col,
} from "antd";
import dateformat from 'dateformat'
import ReactSelect from 'react-select';
import { useContext, useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import Gift from "../../models/Gift";
import Card from "../../models/Card";
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.TELCO,
        dataIndex: 'Telco',
        key: 'Telco',
    },
    {
        title: VN.SERIAL,
        dataIndex: 'Serial',
        key: 'Serial',
    },
    {
        title: VN.PINCODE,
        dataIndex: 'PinCode',
        key: 'PinCode',
    },
    {
        title: VN.REMAIN,
        dataIndex: 'Amount',
        key: 'Amount',
    },
    {
        title: VN.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status) => utils.mapCardStatus(status),
        filters: [
            {
                text: VN.UNUSED,
                value: constants.CARD_STATUS.UNUSED,
            },
            {
                text: VN.USED,
                value: constants.CARD_STATUS.USED,
            },

        ],
        onFilter: (value, record) => record.status === value
    },
    {
        title: VN.UPDATED_AT,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) => dateformat(updatedAt, constants.DATE_FORMAT)
    },
    {
        title: VN.CREATED_AT,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => dateformat(createdAt, constants.DATE_FORMAT)
    },
    // {
    //     title: VN.ACTION,
    //     key: "actions",
    //     dataIndex: 'actions',
    // },
];

export default function Page() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);

    const deleteHandler = async function (id) {
        try {
            // const response = await Request.xhr(
            //     `${constants.API_DOMAIN}/live/delete`,
            //     false,
            //     { liveId: id },
            //     "POST"

            // )
            // if (response.status === 200) {
            //     await fetchData()
            // }

        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/card`)
            let data = utils.mapDataModel(response.data, Card)
            for (let item of data) {
                item.actions = <ActionButtons
                    enableDelete={false}
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                        }
                    })}
                // onDelete={() => deleteHandler(item.id)}
                />
            }
            setData(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    const submitData = async function (formData) {
        try {
            let reqData = {
                taikhoan: formData.taikhoan,
                matkhau: formData.matkhau,
                msgCard: `${formData.nhamang.value}:${formData.menhgia.value}:${formData.soluong}`
            }

            const resData = await Request.xhr(
                `${constants.API_DOMAIN}/card/muathe247`,
                false,
                reqData,
                'POST',
                // true
            )
            if (resData.status === true) {
                alert(VN.SUCCESS)
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>

            <Button
                type="primary"
                icon={<PlusOutlined />}
                // onClick={() => navigate('edit')}
                onClick={() => setIsModalVisible(true)}
            >{VN.CREATE}</Button>
            <Modal
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[]}
            >
                <Form
                    {...formItemLayout}
                    onFinish={(value) => submitData(value)}
                >
                    <Form.Item
                        label={VN.ACCOUNT}
                        name="taikhoan"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={VN.PASSWORD}
                        name="matkhau"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Input type={"password"} />
                    </Form.Item>

                    <Form.Item
                        label={VN.CARD_HOST}
                        name="nhamang"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <ReactSelect
                            options={[{
                                label: 'Viettel',
                                value: 'VTT'
                            }, {
                                label: 'Vinaphone',
                                value: 'VNP'
                            }, {
                                label: 'Mobifone',
                                value: 'VMS'
                            }, {
                                label: 'Vietnammobie',
                                value: 'VNM'
                            }]}

                        />
                    </Form.Item>

                    <Form.Item
                        label={VN.VALUE}
                        name="menhgia"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <ReactSelect
                            options={[{
                                label: '10000',
                                value: 10000,
                            }, {
                                label: '20000',
                                value: 20000
                            }, {
                                label: '50000',
                                value: 50000
                            }, {
                                label: '100000',
                                value: 100000,
                            }, {
                                label: '200000',
                                value: 200000,
                            }, {
                                label: '500000',
                                value: 500000,
                            }
                            ]}

                        />
                    </Form.Item>

                    <Form.Item
                        label={VN.AMOUNT}
                        name="soluong"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Input type={"number"} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Button
                            type="primary" htmlType="submit">
                            {VN.CONFIRM}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Table
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }}
                summary={pageData => {

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>{VN.UNUSED}</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    {data.filter((item) => item.status === constants.CARD_STATUS.UNUSED).length}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>{VN.USED}</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    {data.filter((item) => item.status === constants.CARD_STATUS.USED).length}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}

            />
        </>
    );
}

