import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Mission {
    constructor(data = {}) {
        this.name = data.name || ''
        this.total = data.total || 0
        this.add_coin = data.add_coin || 0
        this.type = data.type || 0
        this.add_point = data.add_point || 0

        this.id = data.missionId || null
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.key = this.id
    }
}