
const constants = {
    GOOGLE_OAUTH_CLIENT_KEY: "21257973516-es5lo4cl35n61vdjvddh3q5haab4og3r.apps.googleusercontent.com",
    DATE_FORMAT: 'HH:MM:ss - dd/mm/yyyy',
    //API_DOMAIN: 'http://localhost:3003',
     API_DOMAIN: 'https://gametet.hocmai.vn',
    IMAGE_DEFAULT_HEIGHT: 60,
    IMAGE_DEFAULT_WIDTH: 105,
    ALERT_TIME_OUT: 1000,
    TABLE_SCROLL_X: 100,
    MAXIMUM_LINE_LENGTH: 50,
    NUM_WORD_EACH_LINE: 10,
    ORDER_STATUS: {
        PENDING: 0,
        CHECKED: 1,
        CANCELED: 2,
    },
    CARD_STATUS: {
        UNUSED: 0,
        USED: 1,
    },
}
export default constants
