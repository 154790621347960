import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class EffectCard {
    constructor(data = {}) {
        this.name = data.name || ''
        this.level = data.level || 0
        this.add_point = data.add_point || 0
        this.time_add_point = data.time_add_point || 0
        this.add_leaf = data.add_leaf || 0
        this.time_add_leaf = data.time_add_leaf || 0
        this.reduce_shit_cooldown = data.reduce_shit_cooldown || 0
        this.time_reduce_shit_cooldown = data.time_reduce_shit_cooldown || 0
        this.reduce_water_cooldown = data.reduce_water_cooldown || 0
        this.time_reduce_water_cooldown = data.time_reduce_water_cooldown || 0
        this.time_expired = data.time_expired || 0

        this.id = data.cardEffectId || null
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.key = this.id
    }
}