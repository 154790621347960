import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Consumable {
    constructor(data = {}) {
        this.name = data.name || ''
        this.level = data.level || 0
        this.cost = data.cost || 0
        this.type = data.type || 0
        this.add_point = data.add_point || 0
        this.time_cooldown = data.time_cooldown || 0

        this.id = data.consumableId || null
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.key = this.id
    }
}