const VN = {
    ID: "Mã số",
    NAME: "Tên",
    IMAGE: "Ảnh",
    DESCRIPTION: "Mô tả",
    ACTION: "Thao tác",
    CHANNEL: "Kênh",
    LINK: "Đường dẫn",
    LENGTH: "Độ dài",
    CREATED_AT: "Ngày tải lên",
    CLASS: "Lớp",
    SUBJECT: "Môn học",
    UPLOAD_IMAGE: "Tải ảnh lên",
    CONFIRM: "Xác nhận",
    SUCCESS: "Thành công",
    GENDER: "Giới tính",
    MALE: "Nam",
    FEMALE: "Nữ",
    OTHER: "Khác",
    PLEASE_ENTER_VALUE: "Nhập đầy đủ thông tin",
    CREATE: "Thêm mới",
    EDIT: "Xem/Sửa",
    DELETE: "Xóa",
    YES: "Có",
    NO: "Không",
    REQUIRE_LOGIN: "Yêu cầu đăng nhập",
    URL: "Đường dẫn",
    FIELD_ERROR: "Thông tin đã nhập không đúng",
    START_TIME: "Thời gian bắt đầu",
    END_TIME: "Thời gian kết thúc",
    TEST: "Thử nghiệm",
    CANCEL: "Hủy bỏ",
    ARE_YOU_SURE_TO_DELETE: "Bạn có chắc không?",
    TEACHER: "Giáo viên",
    METHOD: "Hình thức",
    LIVE_NOW: "Phát trực tiếp",
    LIVE_LATER: "Hẹn giờ phát",
    SOURCE_LIVE: "Nguồn phát video",
    SMALL_COVER: "Ảnh bìa nhỏ",
    MEDIUM_COVER: "Ảnh bìa vừa",
    LARGE_COVER: "Ảnh bìa lớn",
    SHOW_TIME: "Thời gian phát",
    SIGN_IN: "Đăng nhập",
    ENTER_USERNAME_AND_PASSWORD: "Điền tên đăng nhập và mật khẩu",
    USERNAME: "Tên đăng nhập",
    PASSWORD: "Mật khẩu",
    REMEMBER_PASSWORD: "Nhớ mật khẩu",
    ERROR_HAPPENED: "Có lỗi xảy ra",
    BANNER: "HOCMAI GAME TET",
    MORE_INFO: "Thông tin thêm",
    LIVESTREAM: "Livestream",
    SIGN_OUT: "Đăng xuất",
    COMING: "Chưa phát",
    SHOWING: "Đang phát",
    CLOSED: "Đã dừng",
    STATUS: "Trạng thái",
    NOT_ENOUGH_IMAGE: "Chưa chèn ảnh",
    INVISIBLE: "Không khả dụng",
    ERROR: "Xảy ra lỗi",
    HOCMAI_PRIMARY_ANDROID: "Học Mãi tiểu học - Android",
    HOCMAI_PRIMARY_IOS: "Học Mãi tiểu học - IOS",
    HOCMAI_ANDROID: "Học Mãi - Android",
    HOCMAI_IOS: "Học Mãi - IOS",
    PKGNAME: "Danh sách app",
    NOTIFICATION_CONFIG: "Thiết lập thông báo",
    NOTIFICATION: "Thông báo",
    EVENT: "Sự kiện",
    NOTIFICATION_TYPE: "Loại thông báo",
    TARGET: "Đối tượng",
    INDIVIDUAL: "Cá nhân",
    GROUP: "Nhóm",
    ALL: "Toàn bộ",
    TITLE: "Tiêu đề",
    ADD: "Thêm",
    CONTENT: "Nội dung",
    SESSION_EXPIRED: "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại",

    REWARD: "Phần thưởng",
    GIFTBOX: "Hộp quà",
    UPDATED_AT: "Sửa lần cuối",
    REMAIN: "Còn lại",
    COUNT_USED: "Đã sử dụng",
    GIFT: "Quà",
    PERCENT_SEND: "Tỉ lệ trúng (%)",
    COIN_FROM: "Xu tối thiểu",
    COIN_TO: "Xu tối đa",
    NUM_LIMIT_DAY: "Tối đa rơi mỗi ngày",
    PRICE: "Giá",
    TELCO: "Nhà mạng",
    PINCODE: "Mã PIN",
    SERIAL: "Số Serial",
    CARD: "Thẻ cào",
    VALID_DATE: "Thời gian hiệu lực",
    VOUCHER: "Voucher",
    SHOP: "Cửa hàng",
    TYPE: "Loại",
    ADD_POINT: "Thêm điểm",
    ADD_LEAF: "Thêm lá",
    REDUCE_COOLDOWN: "Tăng sinh trưởng",
    REDUCE_WATER: "Tăng tưới nước (giây)",
    ITEM: "Vật phẩm",
    POT: "Chậu",
    TIME_COOLDOWN: "Thời gian chờ",
    CONSUMABLE: "Vật phẩm tiêu thụ",
    EFFECT_CARD: "Thẻ hiệu ứng",
    TIME_ADD_POINT: "Thời gian chờ thêm điểm",
    TIME_ADD_LEAF: "Thời gian chờ thêm lá",
    REDUCE_SHIT_COOLDOWN: "Giảm thời gian bón phân",
    TIME_REDUCE_SHIT_COOLDOWN: "Giảm thời gian chờ bón phân",
    TIME_REDUCE_WATER_COOLDOWN: "Giảm thời gian chờ tưới nước",
    TIME_EXPIRE: "Hết hạn sau (giây)",
    MISSION: "Nhiệm vụ",
    ADD_COIN: "Thêm xu",
    TOTAL: "Còn lại",
    ORDER: "Đơn hàng",
    FULLNAME: "Tên",
    PHONE: "Số điện thoại",
    EMAIL: "Email",
    TIME: "Thời gian",
    ADDRESS: "Địa chỉ",
    CHECKED: "Đã xác nhận",
    CANCELED: "Đã hủy bỏ",
    PENDING: "Đang chờ",
    QUESTION: "Câu hỏi",
    CATEGORY: "Thể loại",
    LEVEL: "Cấp độ",
    POINT: "Điểm",
    CORRECT: "Đáp án",
    UPLOAD: "Tải lên",
    ACCOUNT: "Tài khoản",
    CARD_HOST: "Nhà mạng",
    VALUE: "Mệnh giá",
    AMOUNT: "Số lượng",
    UPLOAD_EXCEL: "Tải lên file excel",
    CONFIG: "Game Config",
    CONTEST: "Contest câu hỏi",
    NUM_FREE_PLAY: "Số lần chơi miễn phí",
    NUM_QUESTION: "Số câu hỏi",
    COIN_PER_QUESTION: "Xu nhận được mỗi câu",
    BONUS: "Thưởng thêm",
    TIME_PER_QUESTION: "Thời gian mỗi câu",
    ONE_TU_TI: "Game oẳn tù tì",
    COIN_JOIN: "Tiền phế",
    TIME_PER_TURN: "Thời gian mỗi lượt",
    O_AN_QUAN: "Ô ăn quan",
    LIST_BET: "Tiền cược",
    CARO: "Cờ Caro",
    KEOCO: "Kéo co",
    TARGET_DONATE_LEAF: "Mục tiêu donate mầm lá",
    COIN: "Xu",
    COIN_DAILY: "Xu thưởng hàng ngày",
    COIN_BONUS_INVITE: "Xu thưởng mời bạn",
    TIME_START_LIXI: "Thời gian bắt đầu phát lì xì",
    TIME_FINISH_LIXI: "Thời gian kết thúc phát lì xì",
    TIME_FINISH_DONATE: "Thời gian kết thúc donate",
    TIME_ADD_LIXI: "Các khung giờ phát lì xì (Tính theo GMT +0)",
    START: "Bắt đầu",
    END: "Kết thúc",
    USER: "Người chơi",
    LIXI: "Lì xì",
    FIRST_NAME: "Tên",
    LAST_NAME: "Họ",
    READ_FILE: "Đọc file",
    USED: "Đã dùng",
    UNUSED: "Chưa dùng",
    SUMMARY: "Tổng cộng",
    BOT_MAX_BET: "Tiền cược tối đa của bot trong 1 ván",
    BOT_MAX_LOSE: "Số tiền tối đa user thắng từ bot 1 ngày",
}
export default VN