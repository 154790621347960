
import {
    Button,
    Image,
    Table,
    Tag,
} from "antd";
import dateformat from 'dateformat'
import { useContext, useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import Mission from "../../models/Mission";


const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'name',
        key: 'name',
        render: (name) => utils.cutLongString(name),
    },
    {
        title: VN.ADD_COIN,
        dataIndex: 'add_coin',
        key: 'add_coin',
    },
    {
        title: VN.TOTAL,
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: VN.TYPE,
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: VN.UPDATED_AT,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) => dateformat(updatedAt, constants.DATE_FORMAT)
    },
    {
        title: VN.CREATED_AT,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => dateformat(createdAt, constants.DATE_FORMAT)
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions',
    },
];

export default function Page() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const deleteHandler = async function (id) {
        try {
            // const response = await Request.xhr(
            //     `${constants.API_DOMAIN}/live/delete`,
            //     false,
            //     { liveId: id },
            //     "POST"

            // )
            // if (response.status === 200) {
            //     await fetchData()
            // }

        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/mission`)
            let data = utils.mapDataModel(response.data, Mission)
            for (let item of data) {
                const data = { ...item }
                item.actions = <ActionButtons
                    enableDelete={false}
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                            oldData: { ...data }
                        }
                    })}
                // onDelete={() => deleteHandler(item.id)}
                />
            }
            setData(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            {/* <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('edit')}
            >{VN.CREATE}</Button> */}
            <Table
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

