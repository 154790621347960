/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo_hocmai.png";
import {
  PlaySquareOutlined, BellOutlined, ProfileOutlined, ReadOutlined, TeamOutlined, UserOutlined, VideoCameraAddOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import VN from '../../vn'
import utils from "../../utils";
function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const role = JSON.parse(window.localStorage.getItem("role"))
  if (!role) {
    alert(VN.SESSION_EXPIRED)
    setTimeout(() => {
      utils.removeUserSavedInfo()
    }, 2000)
    return null
  }
  const roleId = role.id
  if (roleId === undefined || roleId === null) {
    alert(VN.SESSION_EXPIRED)
    setTimeout(() => {
      utils.removeUserSavedInfo()
    }, 2000)
    return null
  }
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>{VN.BANNER}</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.CONFIG}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/config">
            <span
              className="icon"
              style={{
                background: page === "config" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.CONFIG}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2, 3].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.USER}
        </Menu.Item>}

        {[0, 1, 2, 3].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/user">
            <span
              className="icon"
              style={{
                background: page === "user" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.USER}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.QUESTION}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/question">
            <span
              className="icon"
              style={{
                background: page === "question" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.QUESTION}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.ORDER}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item key="1">
          <NavLink to="/order">
            <span
              className="icon"
              style={{
                background: page === "order" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.ORDER}</span>
          </NavLink>
        </Menu.Item>}


        {[0, 1, 2, 3].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.REWARD}
        </Menu.Item>}
        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item key="1">
          <NavLink to="/boxgift">
            <span
              className="icon"
              style={{
                background: page === "boxgift" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.GIFTBOX}</span>
          </NavLink>
        </Menu.Item>
        }
        {[0, 1, 2, 3].indexOf(roleId) > -1 && <Menu.Item key="2">
          <NavLink to="/gift">
            <span
              className="icon"
              style={{
                background: page === "gift" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.GIFT}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item key="3">
          <NavLink to="/card">
            <span
              className="icon"
              style={{
                background: page === "card" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.CARD}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item key="4">
          <NavLink to="/voucher">
            <span
              className="icon"
              style={{
                background: page === "voucher" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.VOUCHER}</span>
          </NavLink>
        </Menu.Item>}



        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.SHOP}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item key="1">
          <NavLink to="/shop">
            <span
              className="icon"
              style={{
                background: page === "shop" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.SHOP}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.ITEM}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/pot">
            <span
              className="icon"
              style={{
                background: page === "pot" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.POT}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/consumable">
            <span
              className="icon"
              style={{
                background: page === "consumable" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.CONSUMABLE}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/effectCard">
            <span
              className="icon"
              style={{
                background: page === "effectCard" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.EFFECT_CARD}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.MISSION}
        </Menu.Item>}

        {[0, 1, 2].indexOf(roleId) > -1 && <Menu.Item>
          <NavLink to="/mission">
            <span
              className="icon"
              style={{
                background: page === "mission" ? color : "",
              }}
            >
              <MinusOutlined />
            </span>
            <span className="label">{VN.MISSION}</span>
          </NavLink>
        </Menu.Item>}




      </Menu>

      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          {/* <span className="icon" style={{ color }}>
            {''}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button> */}
        </div>
      </div>
    </>
  );
}

export default Sidenav;
