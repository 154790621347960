import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Card {
    constructor(data = {}) {
        this.Telco = data.Telco || ''
        this.PinCode = data.PinCode || null
        this.Serial = data.Serial || null
        this.Amount = data.Amount || 0
        this.status = data.status || 0

        this.id = data.cardId || null
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.key = this.id
    }
}