import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class Voucher {
    constructor(data = {}) {
        this.Name = data.Name || ''
        this.PinCode = data.PinCode || null
        this.Serial = data.Serial || null
        this.status = data.status || null
        this.validDate = new Date(data.validDate)

        this.id = data.voucherGalaxyId || null
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        this.key = this.id
    }
}