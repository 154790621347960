import axios from 'axios';
import _ from 'lodash';
import utils from '../utils';

import VN from '../vn';
const Request = {
    _header(useToken, isFormData) {
        let headers = {
            // 'Accept': '*',
            // 'Accept-Encoding': 'gzip, deflate, br',
            // 'Connection': 'keep-alive',
            // 'Content-Length': '<calculated when request is sent>',
            // 'Host': '<calculated when request is sent>',
            'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        };
        // if (useToken) {
        //     headers['Authorization'] = `Bearer ${window.localStorage.getItem('token')}`
        // }
        return headers
    },

    async xhr(URL = '', useToken = false, params = {}, method = 'GET', isFormData = false) {
        try {
            let resData
            switch (method) {
                case "POST":
                    resData = await axios.post(
                        URL,
                        isFormData ? utils.objectToFormData(params) : JSON.stringify(params),
                        {
                            headers: this._header(useToken, isFormData)
                        },
                    )
                    break;
                case "GET":
                    resData = await axios.get(URL, { headers: this._header(useToken, isFormData) })

                    break;
                default:
                    break;
            }

            console.log("FETCHING: ", URL)
            console.log("AT: ", new Date().toLocaleTimeString())
            console.log("METHOD: ", method)
            console.log("TOKEN: ", window.localStorage.getItem('token'))
            if (isFormData) {
                console.log("REQUEST BODY: FORMDATA ", params)
            } else {
                console.log("REQUEST BODY: ", params)
            }
            console.log("RESPONSE: ", resData.data)
            if (resData.data.status !== 200 && resData.data.message) {
                alert(resData.data.message)
            }
            return resData.data;
        } catch (error) {
            console.log("REQUEST ERROR: ", error)
            if (error.response.data.message) {
                if (error.response.status === 401) {
                    alert(VN.SESSION_EXPIRED)
                    setTimeout(() => {
                        window.localStorage.removeItem('token')
                        window.location.reload()
                    }, 2000)
                } else {
                    alert(error.response.data.message)
                }
            } else {
                alert('Có lỗi xảy ra')
            }
            return {
                status: error.response.status,
                message: error.response.message,
            }
        }
    },

    /**
     * Middleware to control any type of error
     *
     */
    async handleResponseError(response) {
        if (!response.ok) {
            // tslint:disable-next-line:one-variable-per-declaration
            let json, errorMessage;
            errorMessage = response.statusText || response.Message;
            try {
                json = await response.json();
            } catch (e) {
                throw new Error(errorMessage);
            }
            throw json;
        }
    },

    toQueryString(obj) {
        return obj
            ? Object.keys(obj)
                .sort()
                .map((key) => {
                    const val = obj[key];
                    if (Array.isArray(val)) {
                        return val
                            .sort()
                            .map((val2) => {
                                return encodeURIComponent(key) + '=' + encodeURIComponent(val2);
                            })
                            .join('&');
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(val);
                })
                .join('&')
            : '';
    },
};

export default Request