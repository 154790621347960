import {
    Table,
    Image,
    Button,
    Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import React from "react";
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import VN from "../../vn";

export default function ActionButtons({
    onEdit, onDelete,
    enableDelete = true,
    editText = VN.EDIT,
    deleteText = VN.DELETE,
}) {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth: 200 }}>
            <Button
                onClick={onEdit}
                type={"primary"} icon={<EditOutlined />}>
                {editText}
            </Button>


            {enableDelete && <Popconfirm
                cancelText={VN.CANCEL}
                okText={VN.CONFIRM}
                onConfirm={onDelete} title={VN.ARE_YOU_SURE_TO_DELETE} icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                <Button danger={true} icon={<DeleteOutlined />}>
                    {deleteText}
                </Button>
            </Popconfirm>}
        </div>
    )
}