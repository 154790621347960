
import {
    Button,
    Image,
    Modal,
    Table,
    Tag,
    Form,
    Radio,
    Upload,
    Input,
    Select,
    Alert,
    DatePicker,
    Row, Col,
    PageHeader,
    TimePicker,
} from "antd";
import dateformat from 'dateformat'
import ReactSelect from 'react-select';
import { useContext, useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import Gift from "../../models/Gift";
import Card from "../../models/Card";
import GameConfig from "../../models/Config";
import dateFormat from 'dateformat'
const formItemLayout = {
    labelCol: {
        span: 22,
    },
    wrapperCol: {
        span: 22,
    },
};

const separator = "-"

export default function Page() {
    const [data, setData] = useState(new GameConfig({}))
    const [loading, setLoading] = useState(false)
    const [timeStartLixi, setTimeStartLixi] = useState(new Date())
    const [timeFinishLixi, setTimeFinishLixi] = useState(new Date())
    const [timeFinishDonate, setTimeFinishDonate] = useState(new Date())

    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/configGame`)
            let data = new GameConfig({ ...response.data })
            setTimeStartLixi(data.timeStartLixi)
            setTimeFinishLixi(data.timeFinishLixi)
            setTimeFinishDonate(data.timeFinishDonate)
            setData(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    const submitData = async function (formData, fieldName = null) {
        // setIsFetching(true)
        try {
            const action = `${constants.API_DOMAIN}/configGame/update`
            let reqData = {}
            if (fieldName !== null) {
                if (formData.listBet) {
                    formData.listBet = formData.listBet.split(separator)
                    formData.listBet = formData.listBet.map((item) => Number(item))
                }

                reqData[fieldName] = { ...formData }
            } else {
                reqData = { ...formData }
            }

            reqData.timeStartLixi = timeStartLixi.toISOString()
            reqData.timeFinishLixi = timeFinishLixi.toISOString()
            reqData.timeFinishDonate = timeFinishDonate.toISOString()
            reqData.timeSlotAddLixi = data.timeSlotAddLixi

            for (const i in data.timeSlotAddLixi) {
                if (formData[`timeLixi${i}Start`] !== undefined) {
                    reqData.timeSlotAddLixi[i].start = dateFormat(formData[`timeLixi${i}Start`]._d, "HH:MM:ss")
                }
                if (formData[`timeLixi${i}End`] !== undefined) {
                    reqData.timeSlotAddLixi[i].end = dateFormat(formData[`timeLixi${i}End`]._d, "HH:MM:ss")
                }
                delete reqData[`timeLixi${i}Start`]
                delete reqData[`timeLixi${i}End`]
            }

            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
                // true
            )

            if (resData.status === true || resData.status === 200) {
                alert(VN.SUCCESS)
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
        // setIsFetching(false)
    }

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value, 'gameContest')}
            >
                <PageHeader
                    ghost={false}
                    title={VN.CONTEST}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameContest.numberPlay}
                                label={VN.NUM_FREE_PLAY}
                                name="numberPlay"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameContest.numberQuestion}
                                label={VN.NUM_QUESTION}
                                name="numberQuestion"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameContest.coin}
                                label={VN.COIN_PER_QUESTION}
                                name="coin"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameContest.time}
                                label={VN.TIME_PER_QUESTION}
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameContest.bonus}
                                label={VN.BONUS}
                                name="bonus"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>

                    </Row>

                </PageHeader>
            </Form>


            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value, 'gameOanTuTi')}
            >
                <PageHeader
                    ghost={false}
                    title={VN.ONE_TU_TI}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.numberPlay}
                                label={VN.NUM_FREE_PLAY}
                                name="numberPlay"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.coinJoin}
                                label={VN.COIN_JOIN}
                                name="coinJoin"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.time}
                                label={VN.TIME_PER_TURN}
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.listBet.join(separator)}
                                label={VN.LIST_BET}
                                name="listBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.botMaxBet}
                                label={VN.BOT_MAX_BET}
                                name="botMaxBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanTuTi.botMaxLose}
                                label={VN.BOT_MAX_LOSE}
                                name="botMaxLose"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>

                </PageHeader>
            </Form>


            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value, 'gameOanquan')}
            >
                <PageHeader
                    ghost={false}
                    title={VN.O_AN_QUAN}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.numberPlay}
                                label={VN.NUM_FREE_PLAY}
                                name="numberPlay"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.coinJoin}
                                label={VN.COIN_JOIN}
                                name="coinJoin"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.time}
                                label={VN.TIME_PER_TURN}
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.listBet.join(separator)}
                                label={VN.LIST_BET}
                                name="listBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.botMaxBet}
                                label={VN.BOT_MAX_BET}
                                name="botMaxBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameOanquan.botMaxLose}
                                label={VN.BOT_MAX_LOSE}
                                name="botMaxLose"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>

                </PageHeader>
            </Form>

            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value, 'gameCaro')}
            >
                <PageHeader
                    ghost={false}
                    title={VN.CARO}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.numberPlay}
                                label={VN.NUM_FREE_PLAY}
                                name="numberPlay"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.coinJoin}
                                label={VN.COIN_JOIN}
                                name="coinJoin"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.time}
                                label={VN.TIME_PER_TURN}
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.listBet.join(separator)}
                                label={VN.LIST_BET}
                                name="listBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.botMaxBet}
                                label={VN.BOT_MAX_BET}
                                name="botMaxBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameCaro.botMaxLose}
                                label={VN.BOT_MAX_LOSE}
                                name="botMaxLose"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>

                </PageHeader>
            </Form>


            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value, 'gameKeoco')}
            >
                <PageHeader
                    ghost={false}
                    title={VN.KEOCO}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameKeoco.numberPlay}
                                label={VN.NUM_FREE_PLAY}
                                name="numberPlay"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameKeoco.coinJoin}
                                label={VN.COIN_JOIN}
                                name="coinJoin"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameKeoco.time}
                                label={VN.TIME_PER_TURN}
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.gameKeoco.listBet.join(separator)}
                                label={VN.LIST_BET}
                                name="listBet"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                    </Row>

                </PageHeader>
            </Form>


            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value)}
            >
                <PageHeader
                    ghost={false}
                    title={VN.TARGET_DONATE_LEAF}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.targetDonateLeaf}
                                label={VN.TARGET_DONATE_LEAF}
                                name="targetDonateLeaf"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </PageHeader>
            </Form>


            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value)}
            >
                <PageHeader
                    ghost={false}
                    title={VN.COIN}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                initialValue={data.coinDaily}
                                label={VN.COIN_DAILY}
                                name="coinDaily"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                initialValue={data.coin_bonus_invite}
                                label={VN.COIN_BONUS_INVITE}
                                name="coin_bonus_invite"
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </PageHeader>
            </Form>

            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value)}
            >
                <PageHeader
                    ghost={false}
                    title={VN.TIME_ADD_LIXI}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    {data.timeSlotAddLixi.map((time, index) =>
                        <Row>
                            <Col span={6}>
                                <Form.Item
                                    label={VN.START}
                                    name={`timeLixi${index}Start`}
                                >
                                    <TimePicker
                                        placeholder={data.timeSlotAddLixi[index].start}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={VN.END}
                                    name={`timeLixi${index}End`}
                                >
                                    <TimePicker
                                        placeholder={data.timeSlotAddLixi[index].end}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </PageHeader>
            </Form>

            <Form
                layout="vertical"
                {...formItemLayout}
                onFinish={(value) => submitData(value)}
            >
                <PageHeader
                    ghost={false}
                    title={VN.TIME}
                    extra={[
                        <Button htmlType="submit" type="primary">
                            {VN.CONFIRM}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label={VN.TIME_START_LIXI}
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <DatePicker showTime
                                    placeholder={dateFormat(timeStartLixi, constants.DATE_FORMAT)}
                                    onChange={(value, dateString) => {
                                        setTimeStartLixi(value._d)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={VN.TIME_FINISH_LIXI}
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <DatePicker showTime
                                    placeholder={dateFormat(timeFinishLixi, constants.DATE_FORMAT)}
                                    onChange={(value, dateString) => {
                                        setTimeFinishLixi(value._d)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={VN.TIME_FINISH_DONATE}
                                rules={[
                                    {
                                        required: true,
                                        message: VN.PLEASE_ENTER_VALUE,
                                    },
                                ]}
                            >
                                <DatePicker showTime
                                    placeholder={dateFormat(timeFinishDonate, constants.DATE_FORMAT)}
                                    onChange={(value, dateString) => {
                                        setTimeFinishDonate(value._d)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </PageHeader>
            </Form>


        </>
    );
}

