import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";

export default class BoxGift {
    constructor(data = {}) {
        this.count_used = data.count_used || 0
        this.remain = data.remain || 0
        this.name = data.name || null
        this.percent_send = data.percent_send || 0
        this.createdAt = new Date(data.createdAt)
        this.updatedAt = new Date(data.updatedAt)
        // this.name = data.name || ""

        this.id = data.boxGiftId || null
        this.key = this.id
    }
}